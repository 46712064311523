import { graphql, useStaticQuery } from "gatsby"

const useLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-Biochem.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return { logo: data.logo.childImageSharp }
}

export default useLogo

import React from "react"
import Header from "../header"
import "./layout.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SEO from "../seo"
import Footer from "../footer"

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <SEO title=" " />
      <Header />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default Layout

import React from "react"
import "./togglerClose.scss"

const TogglerClose = ({ onClick }) => (
  <button className={`togglerClose`} onClick={onClick}>
    <span>X</span>
  </button>
)

export default TogglerClose

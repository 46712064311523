import React from "react"
import Img from "gatsby-image"
import useLogo from "../../hooks/use-logo"

const Logo = () => {
  const { logo } = useLogo()

  return <Img fluid={logo.fluid} className="logo" />
}

export default Logo

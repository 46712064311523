import React, { useState } from "react"
import Facebook from "../icon/facebook"
import Instagram from "../icon/instagram"
import Linkedin from "../icon/linkedin"
import "./menu.scss"
import { Link } from "gatsby"
import Toggler from "./Toggler"
import TogglerClose from "./TogglerClose"

const mountedStyle = {
  animation: "inAnimation 250ms ease-in",
}
const unmountedStyle = {
  animation: "outAnimation 270ms ease-out",
  animationFillMode: "forwards",
}

const Menu = () => {
  const [isActiveToggler, setIsActiveToggler] = useState(false)

  const handleCickToggler = () => setIsActiveToggler(!isActiveToggler)

  return (
    <nav className="menu">
      <Toggler onClick={handleCickToggler} />
      <div
        className={`menu__list ${isActiveToggler ? "menu__list--toggler" : ""}`}
        style={isActiveToggler ? mountedStyle : {}}
      >
        <TogglerClose onClick={handleCickToggler} />
        <ul
          className={`menu__list-items ${
            isActiveToggler ? "menu__list-items--toggler" : ""
          }`}
        >
          <li className="menu__item">
            <Link className="menu__link" to="/">
              Inicio
            </Link>
          </li>
          <li className="menu__item">
            <Link to="/#servicios" className="menu__link">
              Servicios
            </Link>
          </li>
          <li className="menu__item">
            <Link to="/productos" className="menu__link">
              Productos
            </Link>
          </li>
          <li className="menu__item">
            <Link to="/laboratorio" className="menu__link">
              Laboratorio
            </Link>
          </li>
          <li className="menu__item">
            <Link to="/#contacto" className="menu__link">
              Contacto
            </Link>
          </li>
        </ul>
        <ul className="menu__list-items--socials">
          <li className="menu__item">
            <a
              href="https://www.facebook.com/biochemslw"
              className="menu__link"
              target="_blank"
            >
              <Facebook />
            </a>
          </li>
          <li className="menu__item">
            <a
              href="https://www.instagram.com/biochemslw/"
              className="menu__link"
              target="_blank"
            >
              <Instagram />
            </a>
          </li>
          <li className="menu__item">
            <a
              href="https://www.linkedin.com/company/bio-chem-slw"
              className="menu__link"
              target="_blank"
            >
              <Linkedin />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Menu

import React from "react"
import MenuIcon from "../../icon/menu"
import "./toggler.scss"

const Toggler = ({ onClick }) => {
  return (
    <button className={`toggler`} onClick={onClick}>
      <MenuIcon />
    </button>
  )
}

export default Toggler

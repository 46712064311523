import React from "react"
import "./footer.scss"

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer__copyright">Desarrollado por</p>
    </footer>
  )
}

export default Footer

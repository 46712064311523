import React from "react"
import Logo from "../logo"
import Menu from "../menu"
import "./header.scss"

const Header = () => {
  return (
    <header className="header">
      <div className="header__logo">
        <Logo />
      </div>
      <div className="header__menu">
        <Menu />
      </div>
    </header>
  )
}

export default Header
